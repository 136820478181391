import React from 'react'
import RichText from '../../../shared/components/atoms/rich-text/rich-text'
import StackGrid from "react-stack-grid";
import YouTube from 'react-youtube';
import './kit-pillar.scss'

const KitPillar = (props) => {

  /*Youtube component options*/
  const opts = {
    width: '100%',
  };

  const { kitInfo, isMobile, setArrayVideos, setArrayPhotos, setIsGallery, setGalleryDescription } = props

  const handleShowMore = () => {
    let arrayVideosTemp = []
    let arrayPhotosTemp = []
    setArrayVideos(arrayVideosTemp.concat(kitInfo.videos))
    setArrayPhotos(arrayPhotosTemp.concat(kitInfo.galeriaDelPilar))
    setIsGallery(true)
    setGalleryDescription({ title: 'Más fotos de ' + kitInfo.nombreDelPilar, titleMobile: 'KIT DE ADOPCIÓN' })
    window.scrollTo(0, 0)
  }

  return (
    <div className="f-kit-section">

      <RichText
        className="f-kit-section-description"
        jsonInfo={kitInfo.descripcionDelPilar.json}
      />

      <div className="f-kit-info-container">

        <div className="f-kit-info">
          <figure className="f-kit-info-img">
            <img src={kitInfo.imagenKitDeAdopcion.file.url} alt={kitInfo.imagenKitDeAdopcion.description} />
          </figure>
          <p className="f-text f-kit-info-description">{kitInfo.informacionKitDeAdopcion.informacionKitDeAdopcion}</p>
        </div>

        <div className="f-kit-info-send">
          <RichText
            className="f-kit-info-send-description"
            jsonInfo={kitInfo.kitDeAdopcionEntregados.json}
          />
        </div>

      </div>

      <div className="f-galery">
        <h3 className="f-galery-title">Galería multimedia</h3>

        {kitInfo && kitInfo.videos &&
          kitInfo.videos.slice(0, 3).map((video, index) => {
            return (
              <div className="f-galery-video" key={index}>
                <YouTube
                  videoId={video.split('/')[video.split('/').length - 1]}
                  opts={opts}
                />
              </div>
            )
          })}

        <StackGrid
          columnWidth={isMobile ? '30%' : '25%'}
          gutterWidth={isMobile ? 10 : 20}
          monitorImagesLoaded={true}
          className="f-galery-images"
        >
          {kitInfo && kitInfo.galeriaDelPilar &&
            kitInfo.galeriaDelPilar.slice(0, (isMobile ? 3 : 4)).map((img, index) => {
              return (
                <figure key={index} className={"f-galery-img " + (index === (isMobile ? 2 : 3) ? 'show-more' : '')} onClick={(isMobile ? 2 : 3) && handleShowMore}>
                  <img src={img.file.url} alt={img.description} />
                </figure>
              )
            })}

        </StackGrid>
      </div>

    </div>
  )
}

export default KitPillar
