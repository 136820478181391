import React, { useEffect, useState } from "react";
import { graphql } from 'gatsby'
import Layout from "../shared/components/organisms/layout/layout";
import useMobie from '../shared/hooks/useMobile'
import ResponsabilityContent from "../components/organisms/responsability-content/responsability-content";
import SEO from "../shared/components/atoms/seo/seo"
import '../shared/styles/index.scss';

const ContentPage = (props) => {

  // send active modal to layout
  const [modalActive, setModalActive] = useState(null);
  const [isGallery, setIsGallery] = useState(false);
  const isMobile = useMobie();

  const responsabilityInfo = props.data.contentfulPetysResponsabilidadSocial;

  return (
    <Layout activeMenu={3} modalActive={modalActive} logoMobile={!isGallery} logoSearch={!isGallery}>
      <SEO
        title={responsabilityInfo.metaTitulo ? responsabilityInfo.metaTitulo : ''}
        description={responsabilityInfo.metaDescripcion ? responsabilityInfo?.metaDescripcion?.metaDescripcion : ''}
        slug={responsabilityInfo.slug ? responsabilityInfo.slug : ''}
      />
      <ResponsabilityContent
        isGallery={isGallery}
        setIsGallery={setIsGallery}
        modalActive={modalActive}
        isMobile={isMobile}
        responsabilityInfo={responsabilityInfo}
      />
    </Layout>
  )
}

export const query = graphql`
  query RenponsabilityQuery {
    contentfulPetysResponsabilidadSocial {
      titulo
      metaTitulo
      metaDescripcion {
        metaDescripcion
      }
      slug
      tituloPagina
      imagenPetys {
        description
        title
        file {
          url
        }
      }
      descripcionPetys {
        json
      }
      videoResponsabilidadSocial
      informacionPilares {
        json
      }
      pilares {
        titulo
        nombreDelPilar
        descripcionDelPilar {
          json
          descripcionDelPilar
        }
        voluntariadoAnos {
          titulo
          nombreDelPilar
          videos
          galeriaDelPilar {
            description
            title
            file {
              url
            }
          }
        }
        informacionKitDeAdopcion {
          informacionKitDeAdopcion
        }
        imagenKitDeAdopcion {
          description
          file {
            url
          }
          title
        }
        kitDeAdopcionEntregados {
          json
        }
        
        donacionesArenaParaGatos {
          json
        }
       
        donacionesPanitosHumedos {
          json
        }
        galeriaDelPilar {
          title
          description
          file {
            url
          }
        }
        donacionesAnoActual {
          imagenCategoria {
            file {
              url
            }
          }
          descripcionRecomendadosCategorias {
            descripcionRecomendadosCategorias
            json
          }
        }
        donacionesAnosPasados {
          titulo
          nombreDelPilar
          donacionShampooAntipulgas {
            json
          }
          donacionesPanitosHumedos {
            json
          }
          donacionesArenaParaGatos {
            json
          }
        }
        videos
      }
    }
  }
`

export default ContentPage
