import React, { useRef } from "react"
import Slider from "react-slick"
import RichText from "../../../shared/components/atoms/rich-text/rich-text"
import "./donation-slider-history.scss"

const DonationSlickHistory = props => {
  const { donationInfo } = props

  const slider = useRef(null)

  /*Custom arrow*/
  const SampleNextArrow = () => {
    return (
      <div
        className="arrow-next-slick"
        onClick={() => slider.current.slickNext()}
      >
        <i className="fas fa-chevron-right"></i>
      </div>
    )
  }

  /*Custom arrow*/
  const PrevCustomArrow = () => {
    return (
      <div
        className="arrow-prev-slick"
        onClick={() => slider.current.slickPrev()}
      >
        <i className="fas fa-chevron-left"></i>
      </div>
    )
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <PrevCustomArrow />,
  }

  const cardHistoric = donationInfo.donacionesAnosPasados.map(
    (donation, index) => {
      return (
        <div className="f-background-orange">
          <p className="f-donation-last-title">{donation.nombreDelPilar}.</p>
          <div className="f-last-donation-info">
            {donation.donacionesArenaParaGatos && (
              <div className="f-donation-info-card">
                <RichText
                  className="f-donation-wet-description"
                  jsonInfo={donation.donacionesArenaParaGatos.json}
                />
              </div>
            )}

            <div className="f-donation-two-cards">
              {donation.donacionesPanitosHumedos && (
                <div className="f-donation-info-card">
                  <RichText
                    className="f-donation-sand-description"
                    jsonInfo={donation.donacionesPanitosHumedos.json}
                  />
                </div>
              )}

              {donation.donacionShampooAntipulgas && (
                <div className="f-donation-info-card">
                  <RichText
                    className="f-donation-sand-description"
                    jsonInfo={donation.donacionShampooAntipulgas.json}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )
    }
  )
  return (
    <div>
      <Slider {...settings} ref={slider}>
        {cardHistoric}
      </Slider>
    </div>
  )
}

export default DonationSlickHistory
