import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../shared/components/atoms/breadcrumb/breadcrumb'
import RichText from '../../../shared/components/atoms/rich-text/rich-text'
import VoluntaryPillar from '../../../components/molecules/voluntary-pillar/voluntary-pillar';
import KitPillar from '../../../components/molecules/kit-pillar/kit-pillar';
import DonationPillar from '../../../components/molecules/donation-pillar/donation-pillar';
import SectionTitleMobile from '../../../shared/components/atoms/section-title-mobile/section-title-mobile'
import YouTube from 'react-youtube';
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Tabs, Tab } from "@material-ui/core";
import { green, purple } from '../../../shared/styles/variables.scss';
import './responsability-content.scss'

const ResponsabilityContent = (props) => {

  const TabCustom = withStyles((theme) => ({
    root: {
      color: green,
      backgroundColor: "#F2FAE8",
      width: 280
    },
    selected: {
      backgroundColor: purple,
      color: "white",
    },
    wrapper: {
      flexDirection: isMobile ? 'column' : 'row',
      paddingBottom: isMobile ? 10 : 0,
    },
  }))(Tab);

  const useStyles = makeStyles({
    root: {
      overflow: 'unset',
      boxShadow: 'none',
      paddingBottom: 15,
      maxWidth: '1000px',
      margin: '0 auto',
    },
  });

  const { isMobile, responsabilityInfo, setActiveModal, isGallery, setIsGallery } = props
  const voluntaryInfo = responsabilityInfo.pilares[0]
  const kitInfo = responsabilityInfo.pilares[1]
  const donationInfo = responsabilityInfo.pilares[2]
  const [arrayVideos, setArrayVideos] = useState([])
  const [arrayPhotos, setArrayPhotos] = useState([])

  const opts = {
    width: '100%',
  };

  const classes = useStyles();
  const [activePillar, setActivePillar] = useState(0);
  const [galleryDescription, setGalleryDescription] = useState({ title: '', desription: '', titleMobile: '' });

  const handleChange = (event, newPillar) => {
    setActivePillar(newPillar);
  };

  const handleReturnGallery = (event) => {
    event.preventDefault()
    setIsGallery(false)
  };

  return (
    <section className={"f-responsable-section " + (isGallery ? 'f-gallery-section-container' : '')}>

      <div className="f-breadcrum-position ">
        <BreadCrumb mobile={isMobile} />
      </div>

      {isMobile && isGallery && <div className="f-title-gallery-mobile">
        <SectionTitleMobile title={galleryDescription.titleMobile} redirection={"responsabilidad-social/"} onClick={handleReturnGallery} />
      </div>}

      {isGallery && isMobile ?
        null :
        < h1 className="f-title f-title-responsable">{responsabilityInfo.tituloPagina}</h1>
      }

      {!isGallery ?

        <div className="f-responsable-container">

          <div className="f-responsable-description">

            <figure className="f-responsable-description-img">
              <img src={responsabilityInfo.imagenPetys.file.url} alt={responsabilityInfo.imagenPetys.description} />
            </figure>

            {responsabilityInfo.descripcionPetys &&
              <RichText
                className="f-responsable-description-text"
                jsonInfo={responsabilityInfo.descripcionPetys.json}
              />
            }
          </div>

          {responsabilityInfo.videoResponsabilidadSocial &&
            <YouTube
              className='f-responsable-video'
              videoId={responsabilityInfo.videoResponsabilidadSocial.split('/')[responsabilityInfo.videoResponsabilidadSocial.split('/').length - 1]}
              opts={opts}
            />
          }

          {responsabilityInfo.informacionPilares &&
            <RichText
              className="f-responsable-pillar-title"
              jsonInfo={responsabilityInfo.informacionPilares.json}
            />
          }

          <div className="f-pillar-content">

            <Paper className={classes.root + " f-tabs-container"}>
              <Tabs
                value={activePillar}
                onChange={handleChange}
                variant="fullWidth"
                centered={true}
                aria-label="icon label tabs example"
              >
                <TabCustom className="f-tab" icon={<i className="icon-voluntariado f-icon-tab" />} label={voluntaryInfo.nombreDelPilar} />
                <TabCustom className="f-tab" icon={<i className="icon-kit f-icon-tab" />} label="Kit de adopción" />
                <TabCustom className="f-tab" icon={<i className="icon-Donaciones f-icon-tab" />} label="Donaciones" />
              </Tabs>
            </Paper>

            <div className="f-anitame-show f-voluntary" hidden={activePillar !== 0 || activePillar === undefined || activePillar == null} >
              <VoluntaryPillar
                voluntaryInfo={voluntaryInfo}
                setArrayVideos={setArrayVideos}
                setArrayPhotos={setArrayPhotos}
                setIsGallery={setIsGallery}
                setGalleryDescription={setGalleryDescription}
              />
            </div>

            <div className="f-anitame-show f-kit-pillar" hidden={activePillar !== 1} >
              <KitPillar
                kitInfo={kitInfo}
                isMobile={isMobile}
                setArrayVideos={setArrayVideos}
                setArrayPhotos={setArrayPhotos}
                setIsGallery={setIsGallery}
                setGalleryDescription={setGalleryDescription}
              />
            </div>

            <div className="f-anitame-show f-donation-pillar" hidden={activePillar !== 2}>
              <DonationPillar donationInfo={donationInfo} isMobile={isMobile} />
            </div>

          </div>

        </div>

        :

        <div className="f-responsable-container">

          <div className="f-title-description-gallery-container">
            <div className="f-title-gallery-container">
              {isGallery && !isMobile && <i className="far fa-arrow-left" onClick={handleReturnGallery}></i>}
              <h3 className="f-gallery-title">{galleryDescription.title}</h3>
            </div>

            {galleryDescription.description && <p className="f-gallery-description">{galleryDescription.description}</p>}
          </div>

          <div className="f-gallery-containers f-anitame-show">

            {arrayVideos.map((video, index) => {
              let isLastVideo = (index + 1) === arrayVideos.length
              let groupImgs = isLastVideo ? arrayPhotos.slice(index * 3, (index * 3) + arrayPhotos.length) : arrayPhotos.slice(index * 3, (index * 3) + 3)
              return (
                <>
                  {video && <div className="f-video-container" key={index}>
                    <YouTube
                      videoId={video.split('/')[video.split('/').length - 1]}
                      opts={opts}
                    />
                  </div>}
                  {groupImgs.length &&

                    <div className="f-row-imgs-container">
                      {groupImgs.map((image, findex) => {
                        return (
                          <figure className="f-img-container" key={findex}>
                            <img src={image.file.url} alt={image.description} />
                          </figure>
                        )
                      })}
                    </div>
                  }
                </>
              )
            })}

          </div>
        </div>
      }

    </section >
  )
}

export default ResponsabilityContent
