import React from 'react'
import Button from '../../../shared/components/atoms/button/button'
import RichText from '../../../shared/components/atoms/rich-text/rich-text'
import { withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
// import HuellaPurple from '../../../shared/assets/images/huella-lista.svg';
import { green, latoBold, purple } from '../../../shared/styles/variables.scss';
import Gallery from '../../../shared/components/molecules/gallery/gallery';
import YouTube from 'react-youtube';
import './voluntary-pillar.scss'

const VoluntaryPillar = (props) => {

  const AccordionCustom = withStyles((theme) => ({
    root: {
      color: purple,
      fontFamily: latoBold,
      backgroundColor: "#F6F7FB",
      borderRadius: "10px",
      border: "none",
      boxShadow: "0px 2px 5px #9CA5C355",
      width: '100%',
      marginBottom: 20,

      "&:before": {
        height: 0,
      },
      "&:first-child": {
        borderRadius: "10px",
      },
      "&:last-child": {
        borderRadius: "10px",
      },
      "& .f-extand-icon": {
        color: `${purple}!important`,
      }
    },
    expanded: {
      color: `${green}!important`,
      "&i": {
        color: green,
      },
      "& .f-extand-icon": {
        color: `${green}!important`,
      }
    }
  }))(Accordion);

  /*Youtube component options*/
  const opts = {
    width: '100%',
  };

  const { voluntaryInfo, setArrayVideos, setArrayPhotos, setIsGallery, setGalleryDescription } = props

  const filterByYear = (array) => array.sort((a, b) => parseInt(a.nombreDelPilar) > parseInt(b.nombreDelPilar) ? -1 : 0)

  const handleShowMore = (videos, galeriaDelPilar, year) => {
    let arrayVideosTemp = []
    let arrayPhotosTemp = []
    setArrayVideos(arrayVideosTemp.concat(videos))
    setArrayPhotos(arrayPhotosTemp.concat(galeriaDelPilar))
    setIsGallery(true)
    setGalleryDescription({ title: 'Más fotos de ' + year, titleMobile: 'Voluntariado ' + year })
    window.scrollTo(0, 0)
  }

  return (
    <div className="f-voluntary-section">
      <RichText
        className="f-voluntary-section-description"
        jsonInfo={voluntaryInfo.descripcionDelPilar.json}
      />

      <div className="f-acordion-container">
        {voluntaryInfo && voluntaryInfo.voluntariadoAnos &&
          filterByYear(voluntaryInfo.voluntariadoAnos).map((year, index) => {
            // voluntaryInfo.voluntariadoAnos.reverse().map((year, index) => {
            return (
              <AccordionCustom key={index}>
                <AccordionSummary
                  expandIcon={<i className="fal fa-plus f-extand-icon"></i>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >

                  <div className="f-acordion-title-container">
                    <i className="icon-huella f-icon-title"></i>
                    {/* <img src={HuellaPurple} alt="Huella morada" /> */}
                    <h3 className="f-acordion-title">{year.nombreDelPilar}</h3>
                  </div>

                </AccordionSummary>
                <AccordionDetails>
                  <Gallery
                    videos={year.videos}
                    gallery={year.galeriaDelPilar}
                    onShowMore={() => handleShowMore(year.videos, year.galeriaDelPilar, year.nombreDelPilar)}
                  />
                </AccordionDetails>
                {/* <AccordionDetails>
                  <div className="f-grid-container" key={index}>
                    {year && year.videos &&
                      year.videos.map((video, index) => {
                        return (
                          <div className="f-video" key={index}>
                            <YouTube
                              videoId={video.split('/')[video.split('/').length - 1]}
                              opts={opts}
                            />
                          </div>
                        )
                      })
                    }
                  </div>
                </AccordionDetails> */}
              </AccordionCustom>
            )
          })}
      </div>

    </div>
  )
}

export default VoluntaryPillar
