import React from 'react'
import Button from '../../../shared/components/atoms/button/button'
import RichText from '../../../shared/components/atoms/rich-text/rich-text'
import { withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
// import HuellaPurple from '../../../shared/assets/images/huella-lista.svg';
import { green, latoBold, purple } from '../../../shared/styles/variables.scss';
import YouTube from 'react-youtube';
import './donation-pillar.scss'
import DonationSlickHistory from '../donation-slider-history/donation-slider-history';

const DonationPillar = (props) => {

  const AccordionCustom = withStyles((theme) => ({
    root: {
      color: purple,
      fontFamily: latoBold,
      backgroundColor: "#F6F7FB",
      borderRadius: "10px",
      border: "none",
      boxShadow: "0px 2px 5px #9CA5C355",
      width: '100%',
      marginBottom: 20,

      "&:before": {
        height: 0,
      },
      "&:first-child": {
        borderRadius: "10px",
      },
      "&:last-child": {
        borderRadius: "10px",
      },
      "& .f-extand-icon": {
        color: `${purple}!important`,
      }
    },
    expanded: {
      color: `${green}!important`,
      "&i": {
        color: green,
      },
      "& .f-extand-icon": {
        color: `${green}!important`,
      }
    }
  }))(Accordion);

  /*Youtube component options*/
  const opts = {
    width: '100%',
  };

  const { donationInfo } = props

  return (
    <div className="f-donation-section">
      <div className="f-donation-center-content">
        <RichText
          className="f-donation-section-description"
          jsonInfo={donationInfo.descripcionDelPilar.json}
        />
        <h3 className="f-donation-title">Este año llevamos:</h3>

        <div>
          <ul className="f-donation-info">
            {donationInfo.donacionesAnoActual.map((donation, index) => {
              return (
                <li className="f-donation-info-card">
                  <img
                    src={donation.imagenCategoria.file.url}
                    className="f-donation-sand-icon"
                  />
                  <RichText
                    className="f-donation-sand-description"
                    jsonInfo={donation.descripcionRecomendadosCategorias.json}
                  />
                </li>
              )
            })}
          </ul>
        </div>
      </div>

      <div className="f-background-orange">
        <DonationSlickHistory donationInfo={donationInfo} />
      </div>

      {donationInfo.videos && (
        <div className="f-donation-video-bg">
          <div className="f-donation-video">
            <YouTube
              videoId={
                donationInfo.videos[0].split("/")[
                  donationInfo.videos[0].split("/").length - 1
                ]
              }
              opts={opts}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default DonationPillar
