import React from 'react';
import useMobie from '../../../hooks/useMobile'
import YouTube from 'react-youtube';
import StackGrid from "react-stack-grid";
import './gallery.scss';

const Gallery = props => {

  const { titleGallery, gallery, videos, onShowMore } = props
  const isMobile = useMobie()
  /*Youtube component options*/
  const opts = {
    width: '100%',
  };

  return (
    <div className="f-galery">

      {titleGallery && <h3 className="f-galery-title">titleGallery</h3>}

      {videos && videos.length > 0 &&
        videos.slice(0, 1).map((video, index) => {
          return (
            <div className="f-galery-video" key={index}>
              <YouTube
                videoId={video.split('/')[video.split('/').length - 1]}
                opts={opts}
              />
            </div>
          )
        })}

      {gallery && gallery.length > 0 &&
        <StackGrid
          columnWidth={isMobile ? '30%' : '25%'}
          gutterWidth={isMobile ? 10 : 20}
          monitorImagesLoaded={true}
          className="f-galery-images"
        >
          {gallery.slice(0, (isMobile ? 3 : 4)).map((img, index) => {
            return (
              <figure key={index} className={"f-galery-img " + (index === (isMobile ? 2 : 3) ? 'show-more' : '')} onClick={(isMobile ? 2 : 3) && onShowMore}>
                <img src={img.file.url} alt={img.description} />
              </figure>
            )
          })}
        </StackGrid>
      }
    </div>
  )
}

export default Gallery
